import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import BlogRoll from '../components/BlogRoll';

export const IndexPageTemplate = ({ image, title, heading }) => (
	<div>
		<div
			className="full-width-image margin-top-0"
			style={{
				background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${
					!!image.childImageSharp ? image.childImageSharp.fluid.src : image
				})`,
				backgroundSize: `cover`,
			}}
		>
			<div
				style={{
					display: 'flex',
					height: '150px',
					lineHeight: '1',
					justifyContent: 'space-around',
					alignItems: 'left',
					flexDirection: 'column',
				}}
			>
				<h1
					className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
					style={{
						backgroundColor: 'rgb(203, 6, 6)',
						color: 'white',
						lineHeight: '1',
						padding: '0.25em',
					}}
				>
					{title}
				</h1>
				<h3
					className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
					style={{
						backgroundColor: 'rgb(203, 6, 6)',
						color: 'white',
						lineHeight: '1',
						padding: '0.25em',
					}}
				>
					{heading}
				</h3>
			</div>
		</div>
		<section className="section section--gradient">
			<div className="container">
				<div className="section">
					<div className="columns">
						<div className="column is-10 is-offset-1">
							<div className="content">
								<div className="column is-12">
									<BlogRoll />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
);

IndexPageTemplate.propTypes = {
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
	heading: PropTypes.string,
};

const IndexPage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout>
			<IndexPageTemplate image={frontmatter.image} title={frontmatter.title} heading={frontmatter.heading} />
		</Layout>
	);
};

IndexPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default IndexPage;

export const pageQuery = graphql`
	query IndexPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
			frontmatter {
				title
				image {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
				heading
			}
		}
	}
`;
